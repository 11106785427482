.loader-backdrop-indicator {
  background-color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3f6c !important;
  border-radius: 100% !important;
}
